<template>
  <transition v-if="!loading" name="fade">
      <div id="wrapper">
        <sidebar :page="'Home'" :sidebar_data=sidebar_data></sidebar>

        <div class="backdrop" @click="close()"></div>

    <div>
      <nav :class="'navbar bg-transparent'">
        <div class="container-fluid">
          <div class="navbar-brand bars">
            <h4 class="mb-2 mt-2 ms-2">
              <span class="fw-bold text-dark">G</span>
              <span class="text-orange fw-bold">Sort</span>
            </h4>
          </div>

          <span class="navbar-left">
            <div class="navbar-brand me-2">
              <span style="display: -webkit-inline-box;">
                <h4 style="margin-top: 0.2rem">
                  <i class="ph-bell-ringing text-light me-3 nav-icon circle p-2 bg-default"></i>
                </h4>
              </span>
            </div> 

            <div class="navbar-brand dropdown">
              <span data-bs-toggle="dropdown" class="dropdown-toggle" style="display: -webkit-inline-box;">
                  <h4 style="margin-top: 0.2rem">
                  <i class="ph-user text-light me-3 nav-icon circle p-2 bg-default"></i>
                  </h4>
              </span>

              <div class="dropdown-menu">
                <span class="account">
                  <p class="account-text" style="display: inline-block">Hey, {{ user.name.split(' ')[0]  }}!</p>
                </span>
                
                <router-link :to="{name: 'Profile'}" class="dropdown-item">
                  <font-awesome-icon class="icon" :icon="['fa', 'user']" /> 
                  <p class="dropdown-list"> My Account </p>
                </router-link>

                <router-link :to="{name: 'AdminUsers'}" class="dropdown-item">
                  <font-awesome-icon class="icon" :icon="['fa', 'user']" /> 
                  <p class="dropdown-list"> Administration </p>
                </router-link>

                <div class="dropdown-divider"></div>

                <a class="dropdown-item logout" @click="logout()">
                  <font-awesome-icon class="icon logout" :icon="['fa', 'sign-out-alt']" />
                  <p class="dropdown-list"> Logout </p>
                </a>
              </div>
            </div>     
          </span>

          <div class="divider mt-4 d-none"></div>
        </div>
      </nav>
      
      <floating-nav :page="'RequestedPrescriptions'"></floating-nav>

      <div class="body mb-4">
        <div class="container" style="padding: 1rem 2rem 0 2rem">
          <div class="row">
            <div class="col-lg-12 col-md-12">
              <h2 class="text-orange fw-bold mb-0" v-if="user.name">Hi, {{ user.name.split(' ')[0] }}!</h2>
              <small class="text-muted fs-6">Choose a request you'd like to offer a prescription for.</small>
            </div>          
          </div> 

          <div class="row mt-4">
            <no-data class="mt-4" v-if="!requests.length" :message="'No new Orders'"></no-data>
            
            <status v-model:status_data=status_data></status>

            <span v-if="requests.length">
              <div class="col-md-6 col-sm-12 col-lg-6" v-for="({patient, requested_drugs, id}, index) in requests" :key="index">
                <div class="card mb-3" style="cursor: pointer; box-shadow: 0 4px 6px #eee; border-color: #fafafa">
                  <div class="card-body p-3">
                    <div class="row">
                      <h6 class='text-dark fw-bold mt-2 text-capitalize mb-0'>Requests 00{{ id }} </h6>
                    </div>

                    <div class="row mt-3">
                      <div class="col" v-if="screen == 0">
                        <p> 
                          <span class="fw-bold"> Name: </span> 
                          <span class="text-capitalize">{{ patient.name }}</span> 
                        </p>

                        <p> 
                          <span class="fw-bold"> Email: </span> 
                          <span>{{ patient.email }}</span> 
                        </p>

                        <p> 
                          <span class="fw-bold"> Phone Number: </span> 
                          <span> + 254 {{ patient.phone_number }}</span> 
                        </p> 
                      </div> 
                      
                      <div class="col" v-if="screen == 1">
                        <p v-for="({name, stock_type}, index) in requested_drugs" :key="index">
                          <span class="fw-bold">{{ index + 1 }}. {{ name }}: </span>
                          <br />
                          <span>{{ stock_type }}</span>
                        </p>
                      </div>                 
                    </div>                        

                    <div class="row">
                      <div class="d-block">
                        <button class="btn btn-dark text-uppercase fw-bold btn-sm" v-if="screen == 0">
                          <a @click="this.screen = 1" class="text-white text-decoration-none">
                            <p class="mb-0" /> Drugs
                            <font-awesome-icon class="ms-2" :icon="['fa', 'angle-right']"></font-awesome-icon>
                          </a>
                        </button>

                        <button class="btn btn-dark text-uppercase fw-bold btn-sm me-2" v-if="screen == 1">
                          <a @click="this.screen = 0" class="text-white text-decoration-none d-flex">
                            <p class="mb-0" /> <font-awesome-icon class="me-2" style="margin-top: 3px" :icon="['fa', 'angle-left']"></font-awesome-icon> Patient 
                          </a>
                        </button>

                        <button class="btn btn-transparent text-uppercase text-orange fw-bold btn-sm float-end" @click="acceptRequest(id)">
                          <a class="text-orange text-decoration-none">
                            Accept Request <font-awesome-icon class="ms-2" :icon="['fas', 'angle-right']"></font-awesome-icon> 
                          </a>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </span>

            <div id="load-more" class="col-md-12 col-sm-12 p-4 pt-1">
              <div class="card bg-transparent bg-gradient border-0">
                <div class="card-body"></div>
              </div>
            </div>          
          </div>                
        </div>
      </div>
    </div>     
      </div>
  </transition>
</template>

<style lang="scss" scoped>
  @import '../../assets/scss/layout/_navbar.scss';

  .fade-enter-active, .fade-leave-active {
    transition: opacity .5s;
  }
  
  .fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
    opacity: 0;
  }
</style>

<script>
  import Sidebar from '../../components/AutoSidebar'
  import FloatingNav from '../../components/DoctorFloatingNav';
  import NoData from '../../components/NoData'
  import Status from '../../components/Status'

  export default {
    name: 'RequestedPrescriptions',
    components: {
      Sidebar,
      FloatingNav,
      NoData,
      Status
    },   
    data() {
      return {
        requests: [],
        status_data: {
          "success": false,
          "success_message": null,
          "error": false,
          "error_message": null, 
          "loading": false
        },        
        screen: 0
      }
    },  
    computed: {
      user () {
        return this.$store.state.user
      },
      loading () {
        return this.$store.state.loading
      }
    },    
    mounted() {
      this.$store.dispatch("getUser")
      this.getRequests()
    },
    methods: {
        getRequests() {
            this.axios.get('api/request-prescription/requests').then(response => {
                this.requests = response.data.data
            })        
        },
        ifNotLoggedIn() {
            if( !this.user.name) {
                localStorage.removeItem('user')
                this.$router.push({name: 'Login'});
            } else {
            // check if doctor
            if( !this.user.doctor) {
                // if not redirect to home
                this.$router.push({name: 'Home'});
            } else {
                this.$store.dispatch("changeLoading", false)
                this.getRequests()
            }

            this.$store.dispatch("changeLoading", false)
                this.getRequests()
            }
        },
        acceptRequest(id) {
          this.status_data.loading = true

          let data = {
            doctor_id: this.user.id
          }

          this.axios.put('api/request-prescription/' + id, data).then(() => {
            this.status_data.loading = false
            this.status_data.success = true
            this.status_data.error = false
            this.status_data.success_message = 'Accepted!' 

            this.getRequests()
          }).catch(errors => {
            this.status_data.loading = false
            this.status_data.success = false
            this.status_data.error = true
            this.status_data.error_message = errors.message
          })
        }    
    }
}    
</script>
